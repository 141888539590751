import {ReactComponent as actualite} from '../images/icons/ic_actualite.svg';
import {ReactComponent as agenda} from '../images/icons/ic_agenda.svg';
import closeEvent, {ReactComponent as close} from '../images/icons/ic_close.svg';
import {ReactComponent as commune} from '../images/icons/ic_commune.svg';
import {ReactComponent as localisation} from '../images/icons/ic_localisation.svg';
import marker from '../images/icons/ic_marker.svg';
import {ReactComponent as mobilite} from '../images/icons/ic_mobilite.svg';
import {ReactComponent as open} from '../images/icons/ic_open.svg';
import {ReactComponent as plan} from '../images/icons/ic_plan.svg';
import {ReactComponent as pointer} from '../images/icons/ic_pointer.svg';
import swipe_h from '../images/icons/ic_swipe_h.svg';
import swipe_v from '../images/icons/ic_swipe_v.svg';
import bettendorf from '../images/logos/logo_bettendorf.svg';
import bettendorfDark from '../images/logos/logo_bettendorf_negatif.svg';
import diekirch from '../images/logos/logo_diekirch.svg';
import dudelange from '../images/logos/logo_dudelange.svg';
import dudelangeDark from '../images/logos/logo_dudelange_negatif.svg';
import erpeldange from '../images/logos/logo_erpeldange-sur-sure.svg';
import erpeldangeDark from '../images/logos/logo_erpeldange-sur-sure_negatif.svg';
import hesperange from '../images/logos/logo_hesperange.svg';
import hesperangeDark from '../images/logos/logo_hesperange_negatif.svg';
import publilux from '../images/logos/logo_publilux.jpg';
import rumelange from '../images/logos/logo_rumelange.svg';
import rumelangeDark from '../images/logos/logo_rumelange_negatif.svg';
import publismart from '../images/logos/logo_smart-mobility.svg';
import mertert from '../images/logos/logo_mertert.svg';
import verkeiersverbond from '../images/logos/logo_verkeiers_verbond.svg';
import {ReactComponent as touch} from '../images/touch.svg';


const images = {
    logos: {
        publismart: publismart,
        dudelange: dudelange,
        rumelange: rumelange,
        diekirch: diekirch,
        erpeldange: erpeldange,
        bettendorf: bettendorf,
        publilux: publilux,
        verkeiersverbond: verkeiersverbond,
        hesperange: hesperange,
        mertert: mertert
    },
    logosDark: {
        publismart: publismart,
        dudelange: dudelangeDark,
        rumelange: rumelangeDark,
        diekirch: diekirch,
        erpeldange: erpeldangeDark,
        bettendorf: bettendorfDark,
        publilux: publilux,
        verkeiersverbond: verkeiersverbond,
        hesperange: hesperangeDark
    },
    icons: {
        actualite: actualite,
        agenda: agenda,
        close: close,
        commune: commune,
        localisation: localisation,
        mobilite: mobilite,
        open: open,
        plan: plan,
        pointer: pointer,
        touch: touch,
        swipe_h: swipe_h,
        swipe_v: swipe_v,
    },
    map: {
        marker: marker,
    },
    events: {
        closeEvent: closeEvent
    }
};

export default images;

